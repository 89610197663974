
import { defineComponent, onBeforeMount, ref } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import profileService from '../../../services/profile'

export default defineComponent({
  name: 'Dashboard',
  components: {
    HomeOutlined
  },
  setup (props, { emit }) {
    const statistic = ref()
    const columns = [
      {
        title: 'Client',
        dataIndex: 'client'
      },
      {
        title: 'No of Candidate',
        dataIndex: 'candidate'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Subscription Expiration',
        dataIndex: 'subscription',
        slots: { customRender: 'subscription' }
      },
      {
        title: 'Last Login',
        dataIndex: 'lastLogin'
      }
    ]
    const data = [
      {
        key: '1',
        client: 'John',
        candidate: 10,
        email: 'rincy@yopmail.com',
        lastLogin: 'Not logged in yet',
        subscription: 70
      },
      {
        key: '2',
        client: 'Jim',
        candidate: 20,
        email: 'rajan@yopmail.com',
        lastLogin: 'Not logged in yet',
        subscription: 20
      },
      {
        key: '3',
        client: 'Joe',
        candidate: 5,
        email: 'nijesh@yopmail.com',
        lastLogin: 'Not logged in yet',
        subscription: 90
      }
    ]
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getStatistic = async () => {
      try {
        const responce = await profileService.getAdminStatistics()
        statistic.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onBeforeMount(() => {
      emit('updateMenu')
      getStatistic()
    })
    return {
      data,
      columns,
      statistic
    }
  }
})
