<template>
  <div class="main">
    <div class="dashboard content-body">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Dashboard
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="widget">
            <div class="no_of_client">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <img
                    src="./../../../assets/images/dashboard/user.svg"
                    alt="No of Client"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistic?.clients}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">No of clients</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="widget">
            <div class="no_of_course">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <img
                    src="./../../../assets/images/dashboard/users.svg"
                    alt="No of Course"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistic?.candidates}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">No of candidates</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="widget">
            <div class="no_of_candidate">
              <a-row>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <img
                    src="./../../../assets/images/dashboard/file.svg"
                    alt="No of Candidate"
                    srcset=""
                  />
                </a-col>
                <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <div class="value">{{statistic?.course}}</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="text">No of courses</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="registered_users">
            <div class="graph">
              <img
                src="./../../../assets/images/dashboard/user_graph.png"
                alt=""
                srcset=""
                style="width: 100%; margin-top: 20px;"
              />
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="analysis">
            <a-row>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="visitors_analysis">
                  <img
                    src="./../../../assets/images/dashboard/visitor_graph.png"
                    alt=""
                    srcset=""
                    style="width: 100%; margin-top: 20px;"
                  />
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="activity_timeline">
                  <img
                    src="./../../../assets/images/dashboard/activity.png"
                    alt=""
                    srcset=""
                    style="width: 100%; margin-top: 20px;"
                  />
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
      <!--<a-row>
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="subscription-analysis">
            <div class="subscriptin-details">
              <a-table :columns="columns" :data-source="data" rowKey="key">
                <template #subscription="{ text: subscription }">
                  <a-progress :percent="subscription" :show-info="false" />
                </template>
              </a-table>
            </div>
          </div>
        </a-col>
      </a-row>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import profileService from '../../../services/profile'

export default defineComponent({
  name: 'Dashboard',
  components: {
    HomeOutlined
  },
  setup (props, { emit }) {
    const statistic = ref()
    const columns = [
      {
        title: 'Client',
        dataIndex: 'client'
      },
      {
        title: 'No of Candidate',
        dataIndex: 'candidate'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Subscription Expiration',
        dataIndex: 'subscription',
        slots: { customRender: 'subscription' }
      },
      {
        title: 'Last Login',
        dataIndex: 'lastLogin'
      }
    ]
    const data = [
      {
        key: '1',
        client: 'John',
        candidate: 10,
        email: 'rincy@yopmail.com',
        lastLogin: 'Not logged in yet',
        subscription: 70
      },
      {
        key: '2',
        client: 'Jim',
        candidate: 20,
        email: 'rajan@yopmail.com',
        lastLogin: 'Not logged in yet',
        subscription: 20
      },
      {
        key: '3',
        client: 'Joe',
        candidate: 5,
        email: 'nijesh@yopmail.com',
        lastLogin: 'Not logged in yet',
        subscription: 90
      }
    ]
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getStatistic = async () => {
      try {
        const responce = await profileService.getAdminStatistics()
        statistic.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onBeforeMount(() => {
      emit('updateMenu')
      getStatistic()
    })
    return {
      data,
      columns,
      statistic
    }
  }
})
</script>

<style lang="scss">
.dashboard {
  .widget {
    padding: 20px 10px;
    color: #ffffff;
    .no_of_client,
    .no_of_course,
    .no_of_candidate {
      min-height: 125px;
      border-radius: 10px;
      padding: 15px;
      text-align: left;

      img {
        width: 50px;
      }

      .value {
        font-size: 40px;
        font-weight: 400;
        text-align: right;
        padding-right: 5px;
      }

      .text {
        font-weight: bold;
        margin-top: 5px;
      }

      .time {
        font-size: 12px;
      }
    }
    .no_of_client {
      background-image: linear-gradient(to right, #8d2ce2, #4b01e0);
    }
    .no_of_course {
      background-image: linear-gradient(to right, #ff9866, #ff5f62);
    }
    .no_of_candidate {
      background-image: linear-gradient(to right, #36d0dc, #5b87e5);
    }
  }
  .registered_users {
    padding: 10px;
    .graph {
      min-height: 400px;
      background: #ffffff;
      border-radius: 15px;
      box-shadow: 0px 5px 10px #00000014;
    }
  }

  .analysis {
    padding: 10px;
    .visitors_analysis,
    .activity_timeline {
      background: #ffffff;
      border-radius: 15px;
      box-shadow: 0px 5px 10px #00000014;
    }
    .visitors_analysis {
      min-height: 230px;
      margin-bottom: 20px;
    }
    .activity_timeline {
      min-height: 150px;
    }
  }

  .subscription-analysis {
    padding: 10px;
    .subscriptin-details {
      background: #ffffff;
      border-radius: 15px;
      padding: 10px;
      .ant-table-thead > tr > th {
        background: #ffffff;
      }
    }
  }
}
</style>
